.subscription-constructor {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.title {
    display: flex;
    font-weight: normal;
    font-size: 1.2rem;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 10px;
    color: #555;
}